.active {
  position: relative;
  padding-bottom: 5px;
  color: #212134;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 3px solid var(--button-color);
}

.active::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--button-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.inactive::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;

  background-color: var(--button-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.inactive:hover {
  color: #212134;
  margin-bottom: 3px solid var(--button-color);
}

.active:hover {
  color: #212134;
}
.inactive {
  position: relative;
  padding-bottom: 5px;
  color: #212134;
  font-family: var(--font-family-reg);
  font-weight: 400;
  text-decoration: none;
  opacity: 0.4;
}

.inactive:not(.isActive):hover {
  border-bottom: 3px solid var(--button-color);
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
