.SBactive {
  font-family: var(--font-family-bold);
  font-weight: 700;
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  border-bottom: none;

}
.SBactive img{
 filter: invert(100%) sepia(100%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(100%);
}

@media screen and (max-width: 765px) {
  .SBactive {
    text-align: center;
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .SBactive {
    text-align: center;
    font-size: 6px;
  }
}

.SBactive:hover {
  color: #32324d;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.SBactive.SBactive {
  color: #32324d;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.SBinactive.SBinactive {
  font-family: var(--font-family-med);
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  border-bottom: none;
}

@media screen and (max-width: 500px) {
  .SBinactive.SBinactive {
   
    text-align: center; 
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .SBactive.SBactive {
   
    font-size: 10px;
  }
}

.SBinactive:hover {
  color: #32324d;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.SBinactive:hover img{
 filter: invert(100%) sepia(100%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(100%);
  
}
